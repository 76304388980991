
const Payment = () => import('./pages/Payment').then(m => m.default || m)
const Checkout = () => import('./pages/Checkout').then(m => m.default || m)
const CheckoutAlterarPlano = () => import('./pages/CheckoutAlterarPlano').then(m => m.default || m)
const Invoice = () => import('./pages/Invoice').then(m => m.default || m)
const AlterarPlano = () => import('./pages/AlterarPlano').then(m => m.default || m)

export default [
  {
    path: '/payment',
    component: Payment,
    children: [
      {
        path: 'checkout/:planoDetailId',
        name: 'Checkout',
        component: Checkout
      },
      {
        path: 'checkout/:iuguSubscriptionId/alterar/:planoDetailId',
        name: 'CheckoutAlterarPlano',
        component: CheckoutAlterarPlano,
        props: true
      },
      {
        path: 'alterar-plano',
        name: 'AlterarPlano',
        component: AlterarPlano
      }
    ]
  },
  { path: '/payment/invoice/:invoiceId', name: 'Invoice', component: Invoice, props: true }
]
