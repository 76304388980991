import store from '~/store'
import auth from './../modules/auth/routes.js'
import admin from './../modules/admin/routes.js'
import search from './../modules/search/routes.js'
import painel from './../modules/painel/routes.js'
import payment from './../modules/payment/routes.js'
import site, {imovel} from './../modules/site/routes.js'

const Profile = () => import('~/modules/site/pages/Profile').then(m => m.default || m)
const NotFound = () => import('~/modules/errors/pages/404').then(m => m.default || m)

const beforeEnterProfile = async (to, from, next) => {
  try {
    await store.dispatch('site/profile/getProfile', to.params.slug)
    next()
  } catch (e) {
    next({name: 'NotFound'})
  }
}

export default [
  ...site,
  ...search,
  ...auth,
  ...painel,
  ...admin,
  ...payment,
  imovel,
  {path: '/404', name: 'NotFound', component: NotFound},
  {path: '/:slug', name: 'Profile', component: Profile, beforeEnter: beforeEnterProfile},
  {path: '*', component: NotFound}
]
