import swal from 'sweetalert'
import axios from '~/plugins/axios'

async function excluir (id) {
  try {
    await axios.delete('/api/painel/mensagens/' + id)
    return swal('Removida com sucesso!', 'Sua mensagem foi modida para lixeira', 'success')
  } catch (error) {
    console.log(error)
    swal('Erro!', 'Erro ao tentar remover', 'error')
  }
}

async function excluirPermanente (id) {
  try {
    await axios.delete('/api/painel/mensagens/permanente/' + id)
    swal('Excluida permanente com sucesso!', 'Sua mensagem foi removida da nossa base de dados definitivamente', 'success')
  } catch (error) {
    console.log(error)
    swal('Erro!', 'Erro ao tentar remover', 'error')
  }
}

async function excluirListPermanente (listId) {
  try {
    await axios.delete('/api/painel/mensagens/permanente', listId)
    swal('Excluidas permanente com sucesso!', 'Suas mensagens foram removidas da nossa base de dados definitivamente', 'success')
  } catch (error) {
    console.log(error)
    swal('Erro!', 'Erro ao tentar remover', 'error')
  }
}

export default {
  getCaixaDeEntrada (context, params) {
    return axios.get('/api/painel/mensagens', {params: params})
      .then(response => {
        context.commit('updateCaixaDeEntrada', response.data)
      })
      .catch(error => console.log(error))
  },
  getLixeira (context, params) {
    return axios.get('/api/painel/mensagens/lixeira', {params: params})
      .then(response => {
        context.commit('updateLixeira', response.data)
      })
      .catch(error => console.log(error))
  },
  marcarLido (context, params) {
    return axios.get('/api/painel/mensagens/marcar/' + params)
      .then(() => {
        context.commit('updateMsgLida', params)
      })
      .catch(error => console.log(error))
  },

  async destroy (context, params) {
    const value = await swal({
      title: 'Deseja realmente excluir?',
      text: 'Se clicar em "Sim" sua mensagem será movida para Lixeira, porem clicando em "Excluir Permanente" você não terá mais como recupera-la.',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: 'Cancelar',
        ok: {
          text: 'Sim',
          className: 'swal-button--danger',
          closeModal: false
        },
        force: {
          text: 'Excluir Permanente',
          className: 'swal-button--danger',
          closeModal: false
        }
      }
    })
    let canceled = false
    switch (value) {
      case 'ok':
        await excluir(params)
        break
      case 'force':
        await excluirPermanente(params)
        swal('Excluida permanente com sucesso!', 'Sua mensagem foi removida da nossa base de dados definitivamente', 'success')
        break
      default:
        canceled = true
        break
    }
    return canceled
  },

  async restore (context, params) {
    return axios.put('/api/painel/mensagens/restore/' + params)
      .catch(error => console.log(error))
  },

  async destroyPermanente (context, params) {
    const value = await swal({
      title: 'Deseja realmente excluir?',
      text: 'ao "Excluir Permanente" você não terá mais como recupera-la.',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: 'Cancelar',
        ok: {
          text: 'Sim',
          className: 'swal-button--danger',
          closeModal: false
        }
      }
    })
    let canceled = false
    switch (value) {
      case 'ok':
        await excluirPermanente(params)
        break
      default:
        canceled = true
        break
    }
    return canceled
  },

  async destroyListPermanente (context, params) {
    const value = await swal({
      title: 'Deseja realmente excluir?',
      text: 'ao "Excluir Permanente" você não terá mais como recupera-las.',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: 'Cancelar',
        ok: {
          text: 'Sim',
          className: 'swal-button--danger',
          closeModal: false
        }
      }
    })
    let canceled = false
    switch (value) {
      case 'ok':
        await excluirListPermanente(params)
        break
      default:
        canceled = true
        break
    }
    return canceled
  }
}
