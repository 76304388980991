
import mensagens from './mensagens'
import profile from './profile'
import imoveis from './imoveis'
import dashboard from './dashboard'
import financeiro from './financeiro'

export default {
  namespaced: true,
  modules: {
    dashboard,
    mensagens,
    profile,
    imoveis,
    financeiro
  }
}
