<template>
  <nav class="navbar" :class="{'is-fixed-top': fixedTop}" role="navigation" aria-label="main navigation">
    <div class="container is-fluid">
      <div class="navbar-brand">
        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false"
           :class="{ 'is-active': showNav }"
           @click="showNav = !showNav"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>

        <router-link class="navbar-item" :to="{name:'Index'}" exact>
          <img v-if="hero" src="/images/logo-white.png" alt="Digimóveis" title="Digimóveis - fácil de acessar, rapido de encontrar">
          <img v-else src="/images/logo.png" alt="Digimóveis" title="Digimóveis - fácil de acessar, rapido de encontrar">
        </router-link>
      </div>
      <div class="navbar-menu" :class="{ 'is-active': showNav }">
        <div v-if="$route.name !== 'auth.activation'" class="navbar-end">
          <router-link :to="{name:'Imoveis'}" class="navbar-item">
            Imóveis
          </router-link>

          <router-link :to="{name:'Favoritos'}" class="navbar-item">
            <span :class="{'badge is-badge-danger': favoritos.length }" :data-badge="favoritos.length">
              Meus Favoritos
            </span>
          </router-link>

          <router-link v-if="!check" :to="{name: 'Login'}" class="navbar-item">
            Login
          </router-link>

          <div v-if="check && user" class="navbar-item has-dropdown is-hoverable has-text-link">
            <a class="navbar-link has-text-link">
              <b-icon
                icon="account"
              />
              <span>{{ user.firstname }}</span>
            </a>
            <div class="navbar-dropdown ">
              <router-link v-if="user.role === 'C'" :to="{name:'Home'}" class="navbar-item has-text-link is-capitalized">
                Minha Conta
              </router-link>
              <router-link v-if="user.role === 'A'" :to="{name:'Admin'}" class="navbar-item has-text-link is-capitalized">
                Administrativo
              </router-link>
              <router-link v-if="user.role === 'C'" :to="getToProfile" class="navbar-item has-text-link is-capitalized">
                Perfil
              </router-link>
              <router-link v-if="user.role === 'C'" :to="{name:'Home-Imoveis-Create'}" class="navbar-item has-text-link is-capitalized">
                Criar Anúncio
              </router-link>
              <hr class="navbar-divider">
              <a class="navbar-item has-text-link is-capitalized" @click.prevent="logout">
                Sair
              </a>
            </div>
          </div>

          <div v-if="!user" class="navbar-item">
            <router-link :to="{name:'QueroAnunciar'}" class="button is-primary">
              QUERO ANUNCIAR
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Navbar',
  props: ['fixedTop', 'hero'],
  data () {
    return {
      showNav: false
    }
  },
  mounted () {
    if (this.fixedTop && this.$route.name !== 'Index') {
      document.body.classList.add('has-navbar-fixed-top')
    } else {
      document.body.classList.remove('has-navbar-fixed-top')
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      check: 'auth/check',
      favoritos: 'site/favoritos'
    }),
    getToProfile () {
      return {
        name: 'Profile',
        params: {
          slug: this.user.pessoa && this.user.pessoa.profile.name
        }
      }
    }
  },
  methods: {
    async logout () {
      // Log out the user.
      await this.$store.dispatch('auth/logout')
      // Redirect to login.
      this.$router.push({ name: 'Login' })
    },
    resetNav () {
      this.showNav = false
      if (this.fixedTop && this.$route.name !== 'Index') {
        document.body.classList.add('has-navbar-fixed-top')
      } else {
        document.body.classList.remove('has-navbar-fixed-top')
      }
    }
  },
  watch: {
    '$route': 'resetNav'
  }

}
</script>

<style lang="scss" scoped>
.navbar .navbar-brand a.navbar-item.is-active{
      background-color: transparent;
}
</style>
