<template>
  <a :id="containerID" href="#" :class="containerClass" v-scroll-to="{ element: '#app', easing: 'linear' }">{{ text }}</a>
</template>

<script>
export default {
  name: 'ToTop',
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    containerID: {
      type: String,
      required: false,
      default: 'ui-to-top'
    },
    containerClass: {
      type: String,
      required: false,
      default: 'ui-to-top mdi mdi-chevron-up'
    },
    min: {
      type: Number,
      required: false,
      default: 500
    },
    scrollSpeed: {
      type: Number,
      required: false,
      default: 800
    }
  },

  mounted () {
    document.addEventListener('scroll', this.scrollHandler)
  },

  beforeDestroy () {
    document.removeEventListener('scroll', this.scrollHandler)
  },

  methods: {
    scrollHandler () {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      const elem = document.getElementById(this.containerID)
      scrollTop > this.min ? elem.classList.add('active') : elem.classList.remove('active')
    }

  }
}
</script>
