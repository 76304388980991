import { find } from 'lodash'

export default {
  updateCaixaDeEntrada (state, data) {
    state.caixaDeEntrada = data
  },
  updateLixeira (state, data) {
    state.lixeira = data
  },
  updateMsgLida (state, id) {
    find(state.caixaDeEntrada.data, (i) => {
      let matche = i.id === id
      if (matche) {
        i.lida = !i.lida
      }
      return matche
    })

    find(state.lixeira.data, (i) => {
      let matche = i.id === id
      if (matche) {
        i.lida = !i.lida
      }
      return matche
    })
  }
}
