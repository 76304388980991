/* eslint-disable camelcase */
import cookies from 'js-cookie'
export default {
  SAVE_TOKEN (state, {token, expires_in}) {
    state.token = token
    cookies.set('token', token, {expires: expires_in})
  },
  LOGOUT (state) {
    state.user = null
    state.token = null
    state.permissoes = null

    cookies.remove('token')
  },
  FETCH_USER_SUCCESS (state, {user, permissoes, contrato}) {
    state.user = user
    state.permissoes = permissoes
    state.contrato = contrato
  },
  FETCH_USER_FAILURE (state) {
    state.token = null
    cookies.remove('token')
  },

  UPDATE_USER (state, { user }) {
    state.user = user
  }
}
