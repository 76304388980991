import axios from '~/plugins/axios'

export default {
  async getProfile ({commit}, slug) {
    commit('updateImoveis', null)
    const {data} = await axios.get('/api/profile/' + slug)
    commit('updateProfile', data)
  },

  async getImoveis ({commit}, params) {
    try {
      const {data} = await axios.get('/api/profile/' + params.pessoaId + '/imoveis', {params: params})
      commit('updateImoveis', data)
    } catch (e) {
      console.error(e)
    }
  }
}
