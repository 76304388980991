import store from '~/store'
import swal from 'sweetalert'

export default async (to, from, next) => {
  const permissoes = store.getters['auth/permissoes']
  if (!store.getters['auth/check']) {
    const res = await swal({
      title: 'Opss... Verificamos que você não está logado',
      text: 'Para criar um novo anúncios entre com seu email e senha, caso não possua cadastro, clique em "Criar conta!"',
      icon: 'info',
      buttons: {
        confirm: 'Ok!',
        register: {
          text: 'Criar conta!',
          value: 'register'
        }
      }
    })

    if (res === 'register') {
      next({ name: 'register', query: {redirectTo: 'CriarAnuncio'} })
    } else {
      next({ name: 'Login', query: {redirectTo: 'CriarAnuncio'} })
    }
  } else if (permissoes && permissoes.dashboard.contratoCount > 0) {
    if (permissoes.dashboard.contratosAtivos > 0) {
      next({name: 'Home-Imoveis-Create'})
    } else {
      next({name: 'Home'})
    }
  } else {
    next()
  }
}
