const Index = () => import('./pages/Index').then(m => m.default || m)
const Usuarios = () => import('./pages/usuarios/Index').then(m => m.default || m)
const UsuariosList = () => import('./pages/usuarios/List').then(m => m.default || m)

export default [
  { path: '/proadmin', name: 'Admin', component: Index },
  {
    path: '/proadmin/usuarios',
    component: Usuarios,
    children: [
      {
        path: '', name: 'Admin.Usuarios', component: UsuariosList
      }
    ]
  }
]
