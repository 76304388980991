export default {
  updateTotalVisualizacoes (state, data) {
    state.totalVisualizacoes = data
  },
  updateTotalClickTelefones (state, data) {
    state.totalClickTelefones = data
  },
  updateTotalMensagens (state, data) {
    state.totalMensagens = data
  },
  updateQuantidadeImoveis (state, data) {
    state.quantidadeImoveis = data
  },
  updateGraficoVisualizacoes (state, data) {
    state.graficoVisualizacoes = data
  },
  updateMensagens3last (state, data) {
    state.mensagens3last = data
  }
}
