
export default {
  tipoAnuncios: null,
  tipoImoveis: null,
  imoveis: null,
  faixaPreco: null,
  cidades: null,
  tags: [],
  filters: {
    tipo: 'todos',
    tipoImovel: 'todos',
    cidade: null,
    cidadeInput: null,
    precoMin: null,
    precoMax: null,
    numQuartos: [],
    numBanheiros: [],
    numGaragens: []
  }
}
