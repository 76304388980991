
export default {
  tipoAnuncios: state => state.tipoAnuncios,
  tipoImoveis: state => state.tipoImoveis,
  imoveis: state => state.imoveis,
  faixaPreco: state => state.faixaPreco,
  cidades: state => state.cidades,
  tags: state => state.tags,
  filters: stage => stage.filters
}
