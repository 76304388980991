<template>
  <div>
    <navbar hero/>
    <transition :name="transitionName">
      <router-view/>
    </transition>
    <footer-links/>
    <page-footer/>
    <to-top/>
  </div>
</template>

<script>
import Navbar from './../components/layout/Navbar'
import PageFooter from './../components/layout/Footer'
import FooterLinks from './../components/layout/FooterLinks'
import ToTop from './../components/layout/ToTop'
export default {
    name: "Site",
    components: {
        Navbar,
        PageFooter,
        FooterLinks,
        ToTop
    },
    data() {
      return {
        transitionName: 'slide-right'
      }
    },
    watch: {
      '$route' (to, from) {
        const toDepth = to.path.split('/').length
        const fromDepth = from.path.split('/').length
        this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      }
    }
}
</script>
