import Vue from 'vue'

import LazyImage from '~/components/shared/LazyImage'

const Slim = () => import('./Slim').then(m => m.default || m)
const Swiper = () => import('./Swiper').then(m => m.default || m)
const Cleave = () => import('./Cleave').then(m => m.default || m)
const SwiperSlide = () => import('./Slide').then(m => m.default || m)
const SlimCapa = () => import('./SlimCapa').then(m => m.default || m)
const HasError = () => import('./HasError').then(m => m.default || m)
const Lightbox = () => import('./lightbox').then(m => m.default || m)
const IconsHome = () => import('./IconsHome').then(m => m.default || m)
const GoogleMap = () => import('./GoogleMap').then(m => m.default || m)
const Breadcrumb = () => import('./Breadcrumb').then(m => m.default || m)
const CardImovel = () => import('./CardImovel').then(m => m.default || m)
const Datepicker = () => import('./Datepicker').then(m => m.default || m)
const RedesSocias = () => import('./RedesSocias').then(m => m.default || m)
const SlickCarousel = () => import('./SlickCarousel').then(m => m.default || m)
const ImovelForm = () => import('./imovel/ImovelForm').then(m => m.default || m)
const ButtonCheckbox = () => import('./ButtonCheckbox').then(m => m.default || m)
const CompartilharFavoritar = () => import('./CompartilharFavoritar').then(m => m.default || m)

Vue.component('Slim', Slim)
Vue.component('Swiper', Swiper)
Vue.component('Cleave', Cleave)
Vue.component('SlimCapa', SlimCapa)
Vue.component('HasError', HasError)
Vue.component('Lightbox', Lightbox)
Vue.component('IconsHome', IconsHome)
Vue.component('GoogleMap', GoogleMap)
Vue.component('Breadcrumb', Breadcrumb)
Vue.component('CardImovel', CardImovel)
Vue.component('Datepicker', Datepicker)
Vue.component('ImovelForm', ImovelForm)
Vue.component('SwiperSlide', SwiperSlide)
Vue.component('RedesSocias', RedesSocias)
Vue.component('SlickCarousel', SlickCarousel)
Vue.component('ButtonCheckbox', ButtonCheckbox)
Vue.component('CompartilharFavoritar', CompartilharFavoritar)

Vue.component(LazyImage.name, LazyImage)
