import _ from 'lodash'
import {slugify} from '~/helpers/strings'
import axios from '~/plugins/axios'

export default {
  getTipoAnuncios (context) {
    return axios.get('/api/tipoAnuncios')
      .then(response => {
        context.commit('updateTipoAnuncios', response.data)
      })
      .catch(error => console.log(error))
  },

  getTipoImoveis (context) {
    return axios.get('/api/tipoImoveis')
      .then(response => {
        let data = _(response.data)
          .map(item => ({
            nome: item.nome,
            tipo_area: item.tipo_area,
            slug: slugify(item.nome)
          })).value()
        context.commit('updateTipoImoveis', data)
      })
      .catch(error => console.log(error))
  },

  getImoveis (context, params) {
    return axios.get('/api/imoveis', {params: params})
      .then(response => {
        let data = response.data
        context.commit('updateImoveis', data)
        return response
      })
      .catch(error => console.log(error))
  },

  limparImoveis (context) {
    context.commit('updateImoveis', null)
  },

  limparTipos (context) {
    context.commit('updateTipoImoveis', null)
  },

  getFaixaPreco ({commit}, param) {
    return axios.get('/api/imoveis/faixa-preco', param)
      .then(response => {
        commit('updateFaixaPreco', response.data)
      })
      .catch(error => console.log(error))
  },

  getCidades ({commit}) {
    return axios.get('/api/cidades')
      .then(response => {
        let data = _.map(response.data, (item) => ({
          label: item,
          slug: slugify(item)
        }))
        commit('updateCidades', data)
      })
      .catch(error => console.log(error))
  },

  changeTags ({commit}, tags) {
    commit('updateTags', tags)
  },

  addTag ({ commit }, tag) {
    commit('addTag', tag)
  },

  removeTag (context, tag) {
    let tags = context.state.tags
    _.remove(tags, i => i === tag)
    context.commit('updateTags', tags)
  },

  setFilters ({commit}, filters) {
    commit('updateFilters', filters)
  }

}
