<template>
  <div>
    <navbar :fixed-top="true"/>
    <div class="wrapper is-flex">
      <sidebar></sidebar>
      <div class="main-panel is-fullwidth has-background-white-bis">
        <transition name="fade" mode="out-in">
          <router-view/>
        </transition>
      </div>
    </div>
    <to-top/>
  </div>
</template>

<script>
  import ToTop from './../components/layout/ToTop'
  export default {
    name: "Painel",
    created(){
      //this.loadZendDesk();
    },

    beforeDestroy(){
      //document.getElementById("ze-snippet").remove();
    },
    components: {
      navbar: () => import('./../components/layout/painel/Navbar'),
      Sidebar: () => import('./../components/layout/painel/Sidebar'),
      ToTop
    },
    methods: {
      loadZendDesk() {
        if (typeof window.document === 'undefined') {
          // Do nothing if run from server-side
          return
        }

        const zendDeskScript = window.document.createElement('script')

        zendDeskScript.setAttribute('id', 'ze-snippet')
        zendDeskScript.setAttribute('src', 'https://static.zdassets.com/ekr/snippet.js?key=3668da18-7f57-4a73-ac44-a86dc0841114')
        zendDeskScript.setAttribute('async', '')
        zendDeskScript.setAttribute('defer', '')
        window.document.head.appendChild(zendDeskScript)
      }
    }
  }
</script>
