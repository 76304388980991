import Vue from 'vue'
import store from './store'
import router from './router'
import App from './components/App'

import './plugins'
import './filters'
import './components/shared'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  ...App
}).$mount('#app')
