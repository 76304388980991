import Vue from 'vue'

const Clip = () => import('../components/vue-clip/components/Clip/index.js')

const VueClip = {
  install (VueInstance) {
    VueInstance.component('vue-clip', Clip)
  }
}

Vue.use(VueClip)
