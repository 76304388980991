
/** @namespace process.env.MIX_APP_URL_STORAGE */
export const appUrlStorage = process.env.MIX_APP_URL_STORAGE || ''

/** @namespace process.env.MIX_APP_URL */
export const appUrl = process.env.MIX_APP_URL || ''

/** @namespace process.env.MIX_APP_NAME */
export const appName = process.env.MIX_APP_NAME || 'Digimoveis'

/** @namespace process.env.MIX_APP_GOOGLE_MAP_TOKEN */
export const googleMapToken = process.env.MIX_APP_GOOGLE_MAP_TOKEN || 'AIzaSyBBphbwkJbupGCEQ9R7qrcgT2EkEEsQxmQ'