
const STORAGE_KEY = 'favoritos'

export default {
  updateImoveisEmDestaque (state, data) {
    state.imoveisEmDestaque = data
  },
  updateImovel (state, data) {
    state.imovel = data
  },
  updatePlanos (state, data) {
    state.planos = data
  },
  addFavorito (state, data) {
    state.favoritos.push(data)
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state.favoritos))
  },
  removeFavorito (state, data) {
    state.favoritos = state.favoritos.filter(function (value) {
      return value !== data
    })
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state.favoritos))
  },

  updateImoveisFavoritos (state, data) {
    state.imoveisFavoritos = data
  }
}
