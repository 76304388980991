import axios from '~/plugins/axios'

const mapStatus = new Map([
  ['pending', 'Pendente'],
  ['paid', 'Pago'],
  ['canceled', 'Cancelado'],
  ['partially_paid', 'Parcialmente pago'],
  ['refunded', 'Reembolsado'],
  ['expired', 'Expirado'],
  ['authorized', 'Autorizado'],
  ['in_protest', 'Em protesto']
])

export default {
  async fetchFinanceiro ({commit}) {
    try {
      const {data} = await axios.get('/api/painel/financeiro')
      commit('updatePlano', data.plano)
      commit('updateFaturas', data.faturas.map(i => {
        i.status = mapStatus.has(i.status) ? mapStatus.get(i.status) : i.status
        return i;
      }))
    } catch (error) {
      console.error(error)
    }
  },
  clearData ({commit}) {
    commit('updatePlano', null)
    commit('updateFaturas', [])
  },

}
