import menuSideBarService from '~/services/menuSidebarService'

export default {
  user: state => state.user,
  contrato: state => state.contrato,
  token: state => state.token,
  check: state => state.user && state.user.activated !== 0 && state.user.role === 'C',
  checkAdmin: state => state.user && state.user.activated !== 0 && state.user.role === 'A',
  permissoes: state => state.permissoes,
  menuSidebar: menuSideBarService.getMenuSidebar
}
