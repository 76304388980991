<template>
  <section class="hero is-primary is-bold is-fullheight full-page ">
      <div class="hero-head">
          <navbar :hero="true"/>
      </div>

      <div class="hero-body" style="z-index: 4;">
          <transition name="fade" mode="out-in">
              <router-view/>
          </transition>
      </div>
          
      <div class="hero-foot">
      </div>
  </section>
</template>


<script>
import Navbar from './../components/layout/Navbar'
export default {
  name: "Auth",
  components: {
    Navbar,
  }
}
</script>

<style scoped>
.full-page:after, .full-page:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

.is-img{
    background-image: url('/img/full/ponte.jpg');
    background-size: cover;
    background-position: 50%;
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
}

.full-page:after {
    background: #000;
    z-index: 3;
    opacity: .6;
}
</style>
