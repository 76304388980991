import axios from '~/plugins/axios'

export default {
  getDataDashboard (context, pessoaId) {
    return axios.get('/api/painel/dashboard')
      .then(response => {
        context.commit('updateTotalVisualizacoes', response.data.totalVisualizacoes)
        context.commit('updateTotalClickTelefones', response.data.totalClickTelefones)
        context.commit('updateQuantidadeImoveis', response.data.quantidadeImoveis)
        context.commit('updateTotalMensagens', response.data.totalMensagens)
        context.commit('updateMensagens3last', response.data.mensagens3last)
      })
      .catch(error => console.log(error))
  },
  clearData ({commit}) {
    commit('updateTotalVisualizacoes', null)
    commit('updateTotalClickTelefones', null)
    commit('updateQuantidadeImoveis', null)
    commit('updateTotalMensagens', null)
  },
  getTotalVisualizacoes (context, pessoaId) {
    return axios.get('/api/painel/dashboard/total-visualizacoes/' + pessoaId)
      .then(response => {
        context.commit('updateTotalVisualizacoes', response.data)
      })
      .catch(error => console.log(error))
  },
  getTotalClickTelefones (context, pessoaId) {
    return axios.get('/api/painel/dashboard/total-click-telefones/' + pessoaId)
      .then(response => {
        context.commit('updateTotalClickTelefones', response.data)
      })
      .catch(error => console.log(error))
  },
  getTotalMensagens (context, userId) {
    return axios.get('/api/painel/dashboard/total-mensagens/' + userId)
      .then(response => {
        context.commit('updateTotalMensagens', response.data)
      })
      .catch(error => console.log(error))
  },
  getGraficoVisualizacoes (context, by) {
    return axios.get('/api/painel/dashboard/grafico-visualizacoes/' + by)
      .then(response => {
        context.commit('updateGraficoVisualizacoes', response.data)
      })
      .catch(error => console.log(error))
  }
}
