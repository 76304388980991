export default (value) => {
  let retorno = ''
  switch (value) {
    case 'error':
      retorno = 'Erro ao carregar imagem'
      break
    case 'success':
      retorno = 'Enviado com Sucesso!'
      break
    case 'added':
      retorno = 'Adicionado'
      break
    case 'canceled':
      retorno = 'Cancelado'
      break
    case 'uploading':
      retorno = 'Enviando'
      break
    case 'queued':
      retorno = 'Na Fila'
      break
    default :
      retorno = ''
      break
  }
  return retorno
}
