import Menu from './Menu'

export const menuList = (permissons) => {
  return [
    new Menu('Dashboard', 'view-dashboard', {name: 'Home'}, true),
    new Menu('Criar Anúncio', 'home-plus', {name: 'Home-Imoveis-Create'}, true),
    new Menu('Meus Imóveis', 'home', {name: 'Home-Imoveis'}, permissons.meusImoveis),
    new Menu('Mensagens', 'email', {name: 'Home-Mensagens'}, permissons.mensagens),
    new Menu('Financeiro', 'currency-usd', {name: 'Home-Financeiro'}, permissons.financeiro)
  ]
}

export const menuListAdmin = (permissons) => {
  return [
    new Menu('Dashboard', 'view-dashboard', {name: 'Admin'}, true),
    new Menu('Usuários', 'account-group', {name: 'Admin.Usuarios'}, true),
  ]
}
