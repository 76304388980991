<template>
    <footer class="section footer bg-blue-dark">
        <div class="container">
            <nav class="level">
                <div class="level-left">
                    <div class="content">
                        <p class="pull-sm-left"><b>Digimóveis.com</b> &#169; <span id="copyright-year">{{ new Date().getFullYear() }}</span> Todos os direitos reservados</p>
                    </div>
                </div>

                <div class="level-right">
                    <redes-socias class-link="has-text-white"></redes-socias>
                </div>
            </nav>
        </div>
    </footer>
</template>
<script>
export default {
    name: 'pageFooter',
}
</script>

<style scoped>
.footer{
    padding: 3rem 1.5rem;
}
</style>
