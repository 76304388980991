<template>
    <footer class="section is-medium footer-links bg-blue-dark ">
        <div class="container">
            <div class="columns">
                <div class="column is-3">
                    <h4 class="title is-4 text-white">Anúncios</h4>
                    <ul class="list-marked well6 text-left">
                        <li><router-link :to="{name: 'Index'}">Início</router-link></li>
                        <li><router-link :to="{name: 'Imoveis'}">Imóveis</router-link></li>
                        <li><router-link :to="{name: 'Planos'}">Planos e Pacotes</router-link></li>
                    </ul>
                </div>

                <div class="column is-3">
                    <h4 class="title is-4 text-white">Instituicional</h4>
                    <ul class="list-marked well6 text-left ">
                        <li><router-link :to="{name: 'Sobre'}">Sobre nós</router-link></li>
                        <li><router-link :to="{name: 'Sobre'}">Dúvidas Frequentes</router-link></li>
                        <li><router-link :to="{name: 'PoliticasPrivacidade'}" target="_blank">Políticas de Privacidade</router-link></li>
                        <li><router-link :to="{name: 'PoliticasQualidade'}" target="_blank">Políticas de Qualidade</router-link></li>

                    </ul>
                </div>

                <div class="column is-3">
                    <h4 class="title is-4 text-white">Mais</h4>
                    <ul class="list-marked well6 text-left ">
                        <li><router-link to="/blog">Blog</router-link></li>
                        <li><router-link to="/contato">Contato</router-link></li>
                    </ul>
                </div>

                <div class="column is-3">
                    
                </div>
            
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterLink'
}
</script>

<style lang="scss" scoped>
.footer-links{
    .title, ul > li > a {
        color: whitesmoke;
    }
    .list-marked li {
        padding-left: 25px;

        &:before {
            left: 4px;
        }
    }
}
</style>
