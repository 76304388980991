<template>
  <div class="swiper-lazy-box">
    <img ref="imgEle" :data-src="src" class="swiper-lazy" alt="">
  </div>
</template>

<script>
export default {
  name: 'LazyImage',
  props: {
    src: {
      type: String,
      required: true
    }
  },
  updated () {
    this.$refs.imgEle.src = this.src
  }
}
</script>
