import head from 'lodash/head'

export default {
  updateTipoAnuncios (state, data) {
    state.tipoAnuncios = data
  },

  updateTipoImoveis (state, data) {
    state.tipoImoveis = data
  },

  updateImoveis (state, data) {
    if (data && data.current_page === 1) {
      state.imoveis = data
    } else if (data && data.current_page > 1) {
      state.imoveis.data.push(
        ...data.data
      )
      state.imoveis.current_page = data.current_page
      state.imoveis.total = data.total
      state.imoveis.to = data.to
      state.imoveis.from = data.from
    } else {
      state.imoveis = data
    }
  },

  updateFaixaPreco (state, data) {
    if (data && data.length) {
      data = head(data)
      state.faixaPreco = data
    }
  },

  updateCidades (state, data) {
    state.cidades = data
  },

  updateTags (state, data) {
    state.tags = data
  },

  addTag (state, data) {
    state.tags.push(data)
  },

  updateFilters (state, data) {
    state.filters = data
  }
}
