export default {
  updateImoveis (state, data) {
    state.imoveis = data
  },

  updateImovel (state, data) {
    state.imovel = data
  },

  updateMotivos (state, data) {
    state.motivos = data
  },

  updateTipoAnuncios (state, data) {
    state.tipoAnuncios = data
  },

  updateTipoImoveis (state, data) {
    state.tipoImoveis = data
  },

  updateTipoAreas (state, data) {
    state.tipoAreas = data
  }
}
