import axios from '~/plugins/axios'
import swal from 'sweetalert'

export default {

  saveToken ({ commit }, payload) {
    commit('SAVE_TOKEN', payload)
  },

  async fetchUser ({ commit }, router) {
    try {
      const { data } = await axios.get('/api/user')

      commit('FETCH_USER_SUCCESS', { user: data.user, permissoes: data.permissoes, contrato: data.contrato })
    } catch (e) {
      if (e.response && (e.response.status === 403 && e.response.data.message === 'Your email address is not verified.')) {
        const value = await swal(
          'Você ainda não confimou sua Conta!',
          'para ativar sua conta é necessária a confirmação por email. Por favor, Verifique seu email. Caso tenha recebido email, clique em "Reenviar" para solicitar outro',
          'warning',
          {
            buttons: {
              resend: {
                text: 'Reenviar Email',
                value: 'resend',
                visible: true,
                className: 'swal-button--cancel',
                closeModal: false
              },
              ok: {
                text: 'Ok!',
                value: 'ok',
                visible: true,
                className: '',
                closeModal: true
              }

            }
          })
        if (value === 'resend') {
          const {data} = await axios.get('/api/email/resend')
          swal('Enviado com Sucesso!', data, 'success')
        }
        if (router) {
          router.push({name: 'Login'})
        }
        commit('LOGOUT')
      } else {
        commit('FETCH_USER_FAILURE')
      }
    }
  },

  updateUser ({ commit }, payload) {
    commit('UPDATE_USER', payload)
  },

  async logout ({ commit }) {
    try {
      await axios.post('/api/logout')
    } catch (e) { }

    commit('LOGOUT')
  },

  async fetchOauthUrl (ctx, { provider }) {
    const { data } = await axios.post(`/api/oauth/${provider}`)

    return data.url
  }

}
