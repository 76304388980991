import store from '~/store'
import axios from '~/plugins/axios'

export default async (to, from, next) => {
  if (!store.getters['auth/check'] && store.getters['auth/token']) {
    let bearerToken = 'Bearer ' + store.getters['auth/token']
    if ((!axios.defaults.headers.common['Authorization']) ||
      (axios.defaults.headers.common['Authorization'] !== bearerToken)) {
      axios.defaults.headers.common['Authorization'] = bearerToken
    }
    try {
      await store.dispatch('auth/fetchUser')
    } catch (e) { }
  }

  next()
}
