import swal from 'sweetalert'
import axios from '~/plugins/axios'

export default {

  async getImoveis ({commit}, params) {
    try {
      const {data} = await axios.get('/api/painel/imoveis', {params: params})
      commit('updateImoveis', data)
    } catch (error) {
      console.log(error)
    }
  },

  async fecthMotivos ({commit}) {
    try {
      const {data} = await axios.get('/api/painel/imoveis/motivos')
      commit('updateMotivos', data)
    } catch (error) {
      console.log(error)
    }
  },

  async fecthEditImovel ({commit}, imovelId) {
    try {
      const {data} = await axios.get('/api/painel/imoveis/edit/' + imovelId)
      commit('updateImovel', data.data)
    } catch (error) {
      if (error.response.data && error.response.data.warn) {
        const { title, msg } = error.response.data.warn
        swal(title, msg, 'warning')
      }
    }
  },

  async getTipoAreas ({commit}) {
    const {data} = await axios.get('/api/tipoAreas')
    commit('updateTipoAreas', data)
  },

  async getTipoImoveis ({commit}) {
    const {data} = await axios.get('/api/tipoImoveis')
    commit('updateTipoImoveis', data)
  },

  async fecthCreateImovel ({commit}, next) {
    try {
      const {data} = await axios.get('/api/painel/imoveis/create')

      commit('updateTipoAnuncios', data.tipos)
      commit('updateTipoImoveis', data.tipoImoveis)
      commit('updateTipoAreas', data.tipoAreas)

      next()
    } catch (error) {
      if (error.response.data && error.response.data.warn) {
        const { title, msg, redirect } = error.response.data.warn

        next({name: redirect})
        swal(title, msg, 'warning')
      }
    }
  },
}
