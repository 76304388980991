import store from '~/store'

const Home = () => import('./pages/Home').then(m => m.default || m)
const Edit = () => import('./pages/Profile/Edit').then(m => m.default || m)
const Imoveis = () => import('./pages/Imoveis/Index').then(m => m.default || m)
const EditImovel = () => import('./pages/Imoveis/Edit').then(m => m.default || m)
const EditImages = () => import('./pages/Imoveis/EditImages').then(m => m.default || m)
const CreateImovel = () => import('./pages/Imoveis/Create').then(m => m.default || m)
const Mensagens = () => import('./pages/Mensagens/Index').then(m => m.default || m)
const Financeiro = () => import('./pages/Financeiro/Index').then(m => m.default || m)


const beforeEnterCreateImovel = async (to, from, next) => {
  try {
    await store.dispatch('painel/imoveis/fecthCreateImovel', next)
  } catch (e) {
    console.error('Error in beforeEnterCreateImovel => ' + e)
  }
}

export default [
  { path: '/dashboard', name: 'Home', component: Home },
  {
    path: '/dashboard/perfil',
    name: 'Profile-Edit',
    component: Edit
  },
  {
    path: '/dashboard/imoveis',
    name: 'Home-Imoveis',
    component: Imoveis
  },
  {
    path: '/dashboard/imoveis/:imovelId/editar',
    name: 'Home-Imoveis-Edit',
    component: EditImovel
  },
  {
    path: '/dashboard/imoveis/:imovelId/imagens',
    name: 'Home-Imoveis-Edit-Images',
    component: EditImages
  },
  {
    path: '/dashboard/imoveis/create',
    name: 'Home-Imoveis-Create',
    component: CreateImovel,
    beforeEnter: beforeEnterCreateImovel
  },
  {
    path: '/dashboard/mensagens',
    name: 'Home-Mensagens',
    component: Mensagens
  },
  {
    path: '/dashboard/financeiro',
    name: 'Home-Financeiro',
    component: Financeiro
  }
]
