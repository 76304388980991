import axios from '~/plugins/axios'

export default {
  async fetchPlano ({commit}, planoDetailId) {
    try {
      const {data} = await axios.get('/api/checkout/' + planoDetailId)
      commit('updatePlano', data.plano)
      commit('updateCliente', data.cliente)
    } catch (e) {
      throw e
    }
  },

  async fetchLastInvoice ({commit}, subscriptionId) {
    try {
      const {data} = await axios.get('/api/subscription/last-invoice/' + subscriptionId)
      commit('updateFatura', data)
    } catch (e) {
      throw e
    }
  },

  async clearInvoice ({commit}) {
    commit('updateFatura', null)
  }
}
