export default {
  updateProfile (state, data) {
    state.profile = data.data
  },
  updateImoveis (state, data) {
    if (data && data.current_page === 1) {
      state.imoveis = data
    } else if (data && data.current_page > 1) {
      state.imoveis.data.push(
        ...data.data
      )
      state.imoveis.current_page = data.current_page
      state.imoveis.total = data.total
      state.imoveis.to = data.to
      state.imoveis.from = data.from
    } else {
      state.imoveis = data
    }
  }
}
