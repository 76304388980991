export default {
  updatePlano (state, data) {
    state.plano = data
  },

  updateCliente (state, data) {
    state.cliente = data
  },

  updateFatura (state, data) {
    state.fatura = data
  }
}
