import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'

Vue.use(InfiniteLoading, {
  props: {
    spinner: 'waveDots'
    /* other props need to configure */
  },
  system: {
    throttleLimit: 5
    /* other settings need to configure */
  },
  slots: {
    noMore: 'Não tem mais...', // you can pass a string value
    noResults: '...Sem resultados' // you can pass a string value
  }

})
