export default {
  updateDescriptionsContacts (state, data) {
    state.descriptionsContacts = data
  },

  updateCidades (state, data) {
    state.cidades = data
  },

  updateEstados (state, data) {
    state.estados = data
  },

  updateCidadesRaw (state, data) {
    state.cidadesRaw = data
  },

  updateFeatures (state, data) {
    state.features = data
  }
}
