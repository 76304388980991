import { deburr } from 'lodash'

export const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'áäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'
  const to = 'aaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbbddbaa------'

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

export const pluralize = (noun, count, suffix = 's', hasPluralName = false) => {
  if (hasPluralName) {
    if (count > 1) {
      return suffix
    }
    return noun
  }
  return `${noun}${count > 1 ? suffix : ''}`
}

export function somenteNumeros (numsStr) {
  return parseInt(numsStr.replace(/[^0-9]/g, ''))
}

export function callTo (tel) {
  let to = window.device.mobile() ? 'tel:' : 'callto:'
  return to + tel
}

export const sanitize = deburr
export const sanitizeAndLower = value => sanitize(value).toLowerCase()
export const contains = (st, value) => sanitizeAndLower(st).indexOf(sanitizeAndLower(value)) > -1
