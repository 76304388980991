import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import auth from '~/modules/auth/store'
import site from '~/modules/site/store'
import search from '~/modules/search/store'
import painel from '~/modules/painel/store'
import admin from '~/modules/admin/store'
import payment from '~/modules/payment/store'

Vue.use(Vuex)

const config = {
  modules: {
    app,
    site,
    auth,
    search,
    painel,
    admin,
    payment
  }
}

const store = new Vuex.Store(config)

export default store
