
import axios from '~/plugins/axios'

export default {
  getProfile (context, id) {
    return axios.get('/api/painel/profile/' + id)
      .then(response => {
        context.commit('updateProfile', response.data)
      })
      .catch(error => console.log(error))
  },

  async submitUpdatePassword (context, form) {
    const {data} = await axios.put('/api/painel/profile/alterar-senha', form)
    return data
  }

}
