import axios from '~/plugins/axios'

export default {
  getImoveisEmDestaque (context, param) {
    context.rootState.app.loading = true
    return axios.get('/api/imoveis/imoveisEmDestaque/' + param)
      .then(response => {
        context.commit('updateImoveisEmDestaque', response.data)
        context.rootState.app.loading = false
      })
      .catch(error => console.log(error))
  },

  async getImovel ({commit}, slug) {
    const {data} = await axios.get('/api/imovel/' + slug)
    commit('updateImovel', data)
  },

  getPlanos (context) {
    return axios.get('/api/planos/')
      .then(response => {
        context.commit('updatePlanos', response.data)
      })
      .catch(error => console.log(error))
  },

  addFavorito (context, imovelId) {
    return new Promise(() => {
      context.commit('addFavorito', imovelId)
    })
  },

  removeFavorito (context, imovelId) {
    return new Promise(() => {
      context.commit('removeFavorito', imovelId)
    })
  },

  fetchFavoritos (context, params) {
    return axios.get('/api/imoveis/favoritos', {params: params})
      .then(response => {
        context.commit('updateImoveisFavoritos', response.data)
      })
      .catch(error => console.log(error))
  }
}
