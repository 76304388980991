const Login = () => import('~/modules/auth/pages/Login').then(m => m.default || m)
const LoginAdmin = () => import('~/modules/auth/pages/LoginAdmin').then(m => m.default || m)
const PasswordEmail = () => import('~/modules/auth/pages/PasswordEmail').then(m => m.default || m)
const PasswordReset = () => import('~/modules/auth/pages/PasswordReset').then(m => m.default || m)
const QueroAnunciar = () => import('~/modules/auth/pages/QueroAnunciar').then(m => m.default || m)
const Register = () => import('~/modules/auth/pages/Register').then(m => m.default || m)
const RegisterUsuario = () => import('~/modules/auth/pages/RegisterUsuario').then(m => m.default || m)
const RegisterCorretor = () => import('~/modules/auth/pages/RegisterCorretor').then(m => m.default || m)
const RegisterImobiliaria = () => import('~/modules/auth/pages/RegisterImobiliaria').then(m => m.default || m)

export default [
  { path: '/login', name: 'Login', component: Login },
  { path: '/proadmin/login', name: 'admin.login', component: LoginAdmin },
  { path: '/quero-anunciar', name: 'QueroAnunciar', component: QueroAnunciar },
  { path: '/password/reset', name: 'password.request', component: PasswordEmail },
  { path: '/password/reset/:token', name: 'password.reset', component: PasswordReset },
  {
    path: '/register',
    component: Register,
    children: [
      { path: '', name: 'register', component: RegisterUsuario },
      { path: 'corretor', name: 'RegisterCorretor', component: RegisterCorretor },
      { path: 'imobiliaria', name: 'RegisterImobiliaria', component: RegisterImobiliaria }
    ]
  }
]
