<template>
  <div>
    <transition name="slide-fade">
      <navbar :fixed-top="true"
              v-show="(showNavegation && $route.name === 'Index') || $route.name !== 'Index'"
              ref="navigation" />
    </transition>
    <transition :name="transitionName">
      <router-view/>
    </transition>
    <footer-links/>
    <page-footer/>
    <to-top/>
  </div>
</template>

<script>
import Navbar from './../components/layout/Navbar'
import PageFooter from './../components/layout/Footer'
import FooterLinks from './../components/layout/FooterLinks'
import ToTop from './../components/layout/ToTop'
export default {
  name: "Site",
  created() {
    document.addEventListener('scroll', this.scrollHandler);
  },
  components: {
    Navbar,
    PageFooter,
    FooterLinks,
    ToTop
  },
  data() {
    return {
      transitionName: 'slide-right',
      showNavegation: false,
    }
  },
  methods: {

    scrollHandler() {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      const elem = this.$refs.navigation

      this.showNavegation = scrollTop > 45;
    }
  },
  watch: {

    '$route' (to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    }
  }
}
</script>

<style scoped>
  .slide-fade-enter-active, .slide-fade-leave-active { transition: all .5s ease;}
  .slide-fade-leave-active { transition: all .3s ease;}
  .slide-fade-enter, .slide-fade-leave-to { opacity: 0; transform: translateY(-10px);}
</style>
