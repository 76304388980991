import axios from '~/plugins/axios'

export default {
  async getDescriptionsContacts ({commit}) {
    try {
      const {data} = await axios.get('/api/descContatos')
      commit('updateDescriptionsContacts', data)
    } catch (error) {
      console.error(error)
    }
  },

  async getCidades ({commit}) {
    try {
      const {data} = await axios.get('/api/cidades')
      commit('updateCidades', data)
    } catch (error) {
      console.error(error)
    }
  },

  async getEstados ({commit}) {
    try {
      const {data} = await axios.get('/api/estados')
      commit('updateEstados', data)
    } catch (error) {
      console.error(error)
    }
  },

  async getCidadesRaw ({commit}) {
    try {
      const {data} = await axios.get('/api/cidadesRaw')
      commit('updateCidadesRaw', data)
    } catch (error) {
      console.error(error)
    }
  },

  async getFeatures ({commit}) {
    try {
      const {data} = await axios.get('/api/features')
      commit('updateFeatures', data)
    } catch (error) {
      console.error(error)
    }
  }
}
