<template>
    <div id="app">
        <page-loading ref="pageLoading"></page-loading>
        <transition name="page" mode="out-in">
            <component v-if="layout" :is="layout" ></component>
        </transition>
    </div>
</template>

<script>
import PageLoading from './layout/PageLoading'
import { appName } from '../config'

// Load layout components dynamically.
const requireContext = require.context('~/layouts', false, /.*\.vue$/)

const layouts = requireContext.keys()
  .map(file =>
    [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
  )
  .reduce((components, [name, component]) => {
    components[name] = component.default || component
    return components
  }, {})

export default {
    components: {
        PageLoading
    },
    data: () => ({
        layout: null,
        defaultLayout: 'Site',
        description : ''
    }),
    metaInfo () {

        return {
            title: appName,
            titleTemplate: `%s | Digimóveis.com`,
            meta: [
                { vmid: 'description', name: 'description', content: 'Encontre casas e apartamentos novos e usados para venda ou aluguel com os melhores corretores e imobiliárias de sua cidade.' },
            ]
        }
    },
    mounted () {
        this.$loading = this.$refs.pageLoading
    },
    methods: {
        /**
         * Set the application layout.
         *
         * @param {String} layout
         */
        setLayout (layout) {
        if (!layout || !layouts[layout]) {
            layout = this.defaultLayout
        }
        this.layout = layouts[layout]
        }
    }

}
</script>
