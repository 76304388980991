const Imoveis = () => import('./pages/Imoveis').then(m => m.default || m)

export default [
  {
    path: '/imoveis/:tipo(venda|aluguel|lancamento|todos)?/:tipoImovel?/:cidade?',
    name: 'Imoveis',
    component: Imoveis,
    props: true
  }
]
