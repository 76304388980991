import Vue from 'vue'
import capitalize from './capitalize'
import truncate from './truncate'
import upperfirst from './upperfirst'
import uppercase from './uppercase'
import statusClipeFile from './statusClipeFile'

// filter that are registered globaly.
Vue.filter('capitalize', capitalize)
Vue.filter('truncate', truncate)
Vue.filter('upperfirst', upperfirst)
Vue.filter('uppercase', uppercase)
Vue.filter('statusClipeFile', statusClipeFile)
