import store from '~/store'

const Index = () => import('./pages/Index').then(m => m.default || m)
const Sobre = () => import('./pages/Sobre').then(m => m.default || m)
const Imovel = () => import('./pages/Imovel').then(m => m.default || m)
const Planos = () => import('./pages/Planos').then(m => m.default || m)
const Contato = () => import('./pages/Contato').then(m => m.default || m)
const Favoritos = () => import('./pages/Favoritos').then(m => m.default || m)
const CriarAnuncio = () => import('./pages/CriarAnuncio').then(m => m.default || m)
const PoliticasQualidade = () => import('./pages/PoliticasQualidade').then(m => m.default || m)
const PoliticasPrivacidade = () => import('./pages/PoliticasPrivacidade').then(m => m.default || m)

const beforeEnterImovel = async (to, from, next) => {
  try {
    store.state.site.imovel = null
    store.state.site.imovelSimilares = null
    const promises = [store.dispatch('site/getImovel', to.params.slug)]

    if (!store.state.app.features) {
      promises.push(store.dispatch('app/getFeatures'))
    }

    await Promise.all(promises)
    next()
  } catch (e) {
    next({name: 'NotFound'})
  }
}

export default [
  { path: '/', name: 'Index', component: Index },
  { path: '/sobre', name: 'Sobre', component: Sobre },
  { path: '/politicas-qualidade', name: 'PoliticasQualidade', component: PoliticasQualidade },
  { path: '/politicas-privacidade', name: 'PoliticasPrivacidade', component: PoliticasPrivacidade },
  { path: '/contato', name: 'Contato', component: Contato },
  { path: '/favoritos', name: 'Favoritos', component: Favoritos },
  { path: '/planos', name: 'Planos', component: Planos },
  { path: '/criar-anuncio', name: 'CriarAnuncio', component: CriarAnuncio }
]

export const imovel = {
  path: '/imovel/:slug',
  name: 'Imovel',
  component: Imovel,
  beforeEnter: beforeEnterImovel
}
